import React from "react";
import styles from "./Navigation.module.css";
import { useSelector, useDispatch } from "react-redux";
import { setCatigoriesId } from "../../../redux/slices/filterSlice";

function Navigation(props) {
  const dispatch = useDispatch();
  const categoryId = useSelector((state) => state.filter.categoryId);

  const onClickCategoryId = (id) => {
    dispatch(setCatigoriesId(id));
  };
  const navigation = props.navigation.map((item, index) => (
    <li
      key={item.id}
      className={Number(categoryId) === index ? styles.active : " "}
      onClick={() => onClickCategoryId(item.id)}
    >
      {item.name}
    </li>
  ));

  return (
    <div className={styles.navigastion}>
      <div className={styles.fon}></div>
      <div className={styles.videoContainer}>
        <video
          autoplay="autoplay"
          loop
          playsinline="playsinline"
          muted
          className={styles.video}
        >
          <source src="/images/Дым_шашлык.webm" type="video/webm" />
          <source src="/images/Дым_шашлык.mp4" type="video/mp4" />
        </video>
      </div>

      <div>
        <div className={styles.bannerDiv}>
          <div className={styles.bannerBlock}>
            <p className={styles.banner}>Доставка от 30 минут в Королёве</p>
            <p className={styles.deliveryBanner}>
              От 2500 доставка бесплатно{" "}
              <span className={styles.deliveryBanner}>
                *в пределах 3-ех км.
              </span>
            </p>
          </div>
        </div>
        <ul>{navigation}</ul>
      </div>
    </div>
  );
}

export default Navigation;
