import React, { useEffect, useState } from "react";
import styles from "./SousItem.module.css";
import CardButton from "../Item/CardButton";
import { useDispatch, useSelector } from "react-redux";
import {
  setBasketItemMinus,
  addItems,
  removeItems,
} from "../../../redux/slices/basketSlice";
const SousItem = ({
  id,
  name,
  price,
  img,
  categoryName,
  loading,
  isLoading,
}) => {
  const [show, setShow] = useState(false);
  const [items, setItems] = React.useState([]);
  //const [isLoading, setIsLoading] = React.useState(true);
  const dispatch = useDispatch();
  const basketItem = useSelector((state) =>
    state.basket.items.find((obj) => obj.id === id)
  );

  const [isAmountValid, setIsAmountValid] = React.useState(!basketItem);
  const addedAmount = basketItem == undefined ? 0 : basketItem.amount;

  useEffect(() => {
    if (addedAmount === 0) {
      setIsAmountValid(true);
    }
  }, [addedAmount]);
  const addCountPlus = () => {
    const cartItemId = {
      id,
    };
    dispatch(addItems(cartItemId));
  };

  const addCountMinus = () => {
    if (
      (basketItem.id === 76 && basketItem.amount <= 5) ||
      basketItem.amount <= 1
    ) {
      setIsAmountValid(true);
      dispatch(removeItems(id));
    } else {
      dispatch(setBasketItemMinus(id));
    }
  };

  const addOrder = () => {
    const cartItem = {
      id,
      name,
      price,
      img,
      categoryName,
    };
    dispatch(addItems(cartItem));
    setIsAmountValid(false);
  };

  const inputQuantity = isAmountValid ? (
    <button
      className={isLoading ? styles.noActive : styles.basketButton}
      onClick={addOrder}
      disabled={isLoading}
    >
      В корзину
    </button>
  ) : (
    <div className={styles.inputContainer}>
      <div className={styles.input}>
        <div className={styles.quantityButton} onClick={addCountMinus}>
          <p className={styles.count}>-</p>
        </div>
        <div>
          <p className={styles.newInput}>{addedAmount}</p>
        </div>
        <div className={styles.quantityButton} onClick={addCountPlus}>
          <p className={styles.count}>+</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <div className={styles.imgPrice}>
        <img src={img} width={110} alt="Блюдо" />
      </div>
      <div className={styles.title}>
        <h3 className={styles.h3}>{name}</h3>
        <p className={styles.p}>{price} руб</p>
      </div>

      <div className={styles.button}>{inputQuantity}</div>
    </div>
  );
};

export default SousItem;
