import React from "react";
import styles from "./address.module.css";
import telefon from "../../img/telefonV2.svg";

function Address() {
  return (
    <main className={styles.mainAddress}>
      <div className={styles.container}>
        <div>
          <img src={telefon} alt="Телефон" />
        </div>
        <div className={styles.addressBlock}>
          <div className={styles.address}>
            <div>
              <h2>+79318965555</h2>
            </div>
            <div>
              <p>г. Королев, пр-т Космонавтов, вблизи д.26/2</p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Address;
