import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setBasketItemMinus,
  addItems,
  removeItems,
} from "../../../redux/slices/basketSlice";
import { Modal, Button } from "react-bootstrap";
import styles from "./CardButton.module.css";
import { useEffect } from "react";
import axios from "axios";
import SousItem from "../SousItem/SousItem";

function CardButton(props) {
  const { id, name, price, img, categoryName, description } = props.date;
  // const modalDelivery = useSelector((state) => state.basket.delivery);
  const [show, setShow] = useState(false);
  const [items, setItems] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const basketItem = useSelector((state) =>
    state.basket.items.find((obj) => obj.id === id)
  );

  const [isAmountValid, setIsAmountValid] = React.useState(!basketItem);
  const addedAmount = basketItem == undefined ? 0 : basketItem.amount;

  React.useEffect(() => {
    setIsLoading(true);
    axios
      .get("https://64cc1b8e2eafdcdc8519a858.mockapi.io/dimShaslik?category=8")
      .then((arr) => {
        setItems(arr.data);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (addedAmount === 0) {
      setIsAmountValid(true);
    }
  }, [addedAmount]);
  const addCountPlus = () => {
    const cartItemId = {
      id,
    };
    dispatch(addItems(cartItemId));
  };

  const addCountMinus = () => {
    if (
      (basketItem.id === 76 && basketItem.amount <= 5) ||
      basketItem.amount <= 1
    ) {
      setIsAmountValid(true);
      dispatch(removeItems(id));
      setLoading(true);
    } else {
      dispatch(setBasketItemMinus(id));
    }
  };

  const addOrder = () => {
    const cartItem = {
      id,
      name,
      price,
      img,
      categoryName,
    };
    dispatch(addItems(cartItem));
    setIsAmountValid(false);
    setLoading(false);
  };

  const inputQuantity = isAmountValid ? (
    <button
      className={props.isDisabled ? styles.noActive : styles.basketButton}
      onClick={addOrder}
      disabled={props.isDisabled}
    >
      В корзину
    </button>
  ) : (
    <div className={styles.inputContainer}>
      <div className={styles.input}>
        <div className={styles.quantityButton} onClick={addCountMinus}>
          <p>-</p>
        </div>
        <div>
          <p className={styles.newInput}>{addedAmount}</p>
        </div>
        <div className={styles.quantityButton} onClick={addCountPlus}>
          <p>+</p>
        </div>
      </div>
    </div>
  );

  const modal =
    categoryName === "СОУС" || categoryName === "НАПИТКИ" ? (
      inputQuantity
    ) : (
      <>
        <Button variant="primary" onClick={() => setShow(true)}>
          Добавить
        </Button>

        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {name} - {price} руб.
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className={styles.img}>
                <img src={img} width={300} alt="Блюдо" />
              </div>

              {/* <div className={styles.descriptionP}>
          <p className={styles.p}>{description}</p>
        </div> */}
              <div className={styles.addButton}>{inputQuantity}</div>
              <div>
                <h2 className={styles.h2}>Добавить к блюду</h2>
                <div className={styles.sousItem}>
                  {items.map((meal) => (
                    <SousItem
                      key={meal.id}
                      id={meal.id}
                      name={meal.name}
                      img={meal.img}
                      description={meal.description}
                      isLoading={loading}
                      loading={props.isDisabled}
                      price={meal.price}
                      weight={meal.weight}
                      categoryName={meal.categoryName}
                    />
                  ))}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </>
    );

  return (
    <form className={styles.form}>
      <div className={styles.button}>{modal}</div>
    </form>
  );
}

export default CardButton;
